export const getMonthFirstDayAndLastDay = (inputMonth, inputYear) => {
  const firstDayOfMonth = new Date(inputYear, inputMonth, 1);
  const lastDayOfMonth = new Date(inputYear, inputMonth + 1, 0, 23, 59, 59, 999);
  return { firstDayOfMonth, lastDayOfMonth };
};

export const getYearFirstDayAndLastDay = (inputYear) => {
  const firstDayOfYear = new Date(inputYear, 0, 1);
  const lastDayOfYear = new Date(inputYear + 1, 0, 0, 23, 59, 59, 999);
  return { firstDayOfYear, lastDayOfYear };
};

export const fixDataForArea = (inputArray, dataMonth) => {
  const TodaysDate = new Date();

  // to display todays date of the month on Graph for current month only
  let latestY = null;
  if (dataMonth === TodaysDate.getMonth() && !inputArray.find((coordinates) => { latestY = coordinates.y; return coordinates.x.getDate() === TodaysDate.getDate(); })) {
    inputArray.splice(inputArray.length, 0, { x: TodaysDate, y: latestY });
  }

  return inputArray;
};

export const fixDataForBar = (inputArray, dataMonth, dataYear) => {
  const todaysDate = new Date().getDate();
  const isOldData = new Date().getMonth() !== dataMonth;
  const { lastDayOfMonth } = getMonthFirstDayAndLastDay(dataMonth, dataYear);
  const lastDateOfMonth = lastDayOfMonth.getDate();

  // to handle null values till current Date
  for (let i = 0; i < (isOldData ? lastDateOfMonth : todaysDate); i++) {
    if (!inputArray.find((coordinates) => coordinates.x.getDate() === i + 1)) {
      inputArray.splice(i, 0, { x: new Date(dataYear, dataMonth, i + 1), y: 0 });
    }
  }

  return inputArray;
};

export const fixDataForMultibar = (data, mapping, yValues = [], emptyValue) => {
  const newKeys = yValues.reduce((obj, key) => {
    obj[key] = emptyValue === null ? null : 0;
    return obj;
  }, {});

  for (let i = 0; i < mapping.length; i++) {
    if (!data.find((coordinates) => `${coordinates.x}` === `${mapping[i]}`)) {
      data.splice(i, 0, { x: mapping[i], y: emptyValue === null ? null : 0, ...newKeys });
    }
  }
  return data;
}

export const getWeekRange = (inputDate) => {
  const startOfWeek = new Date(inputDate);
  while (startOfWeek.getDay() !== 0) { // 0 represents Sunday
    startOfWeek.setDate(startOfWeek.getDate() - 1);
  }
  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6);
  endOfWeek.setHours(23, 59, 59, 999);
  return { startOfWeek, endOfWeek };
};

export const getMonthRange = (inputDate) => {
  const startOfMonth = new Date(inputDate.getFullYear(), inputDate.getMonth(), 1);
  const endOfMonth = new Date(inputDate.getFullYear(), inputDate.getMonth() + 1, 0);
  endOfMonth.setHours(23, 59, 59, 999);
  return { startOfMonth, endOfMonth };
};

export const getWeeklyDateRanges = (month, year) => {
  const dateRanges = [];
  const firstDayOfMonth = new Date(year, month, 1);

  // Determine the first Monday in the given month
  let startOfWeek = new Date(firstDayOfMonth);
  while (startOfWeek.getDay() !== 0) { // 0 represents Sunday
    startOfWeek.setDate(startOfWeek.getDate() - 1);
  }

  while (startOfWeek.getMonth() === (month - 1 < 0 ? 11 : month - 1) || startOfWeek.getMonth() == month) {
    let endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999);

    dateRanges.push({ startOfWeek: new Date(startOfWeek), endOfWeek });

    // Move to the next week
    startOfWeek.setDate(startOfWeek.getDate() + 7);
  }

  return dateRanges;
}

export const getMonthlyDateRanges = (year) => Array.from({ length: 12 },
  (v, i) => {
    const endDate = new Date(year, i + 1, 0);
    endDate.setHours(23, 59, 59, 999);
    return ({ startOfMonth: new Date(year, i, 1), endOfMonth: endDate })
  })

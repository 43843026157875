import React, { useEffect, useMemo, useReducer, useState } from 'react';
import {
  CalendarLoader,
  HealthDeleteFoodModal,
  HnwAddItemCard,
  HnwCheckbox,
  HnWNumpadModal,
  HnwSetterCard,
  HnwSetterCardList,
} from '../../../../common';
import { useDispatch, useSelector } from 'react-redux';
import './ActivityGoal.scss';
import commonConstant, {
  FOOD_DELETE_MODAL_CANCEL,
  FOOD_DELETE_MODAL_DELETE,
  FOOD_DELETE_MODAL_HEADER,
  GENDER,
  HEALTH_GOAL_TYPES,
  HNW_GOAL_SETTING,
} from '../../../../common/commonConstant';
import activityReducer, {
  HNW_GOAL_SETTING_ACTIVITY_INITIAL_STATE,
  ON_ALL_CHECK_BY_STATE,
  ON_CANCEL_CHECKS,
  ON_CHANGE_ACTIVITIES,
  ON_CHANGE_ACTIVITY,
  ON_CHANGE_EXERCISE_MENU,
  ON_CHECK_EXERCISE_MENU,
  ON_LOAD_ACTIVITY,
} from './activityReducer';
import {
  createActivityGoal,
  fetchActivityGoalAction,
  fetchMenuByExerciseId,
  resetExerciseMenu,
  updateActivityGoal,
} from '../../../../actions/hnwGoalsActions';
import { getDataFromSession } from '../../../../utill.func';
import HnwExerciseMenuPage from '../components/HnwExerciseMenu/HnwExerciseMenuPage';
import {
  getCustomerAge,
  getCustomerGender,
} from '../../../../services/healthWellness/healthWellness.service';
import { getDefaultValues } from '../../../../services/healthWellness/hnwGoals.service';
import { round } from '../../../../services';
import { fetchBmiData, getHnwAllHealhGoalList } from '../../../../actions';
import { ActivityGoalCreatePayload } from '../../../../model/GoalSetting.model';
import HnWActivityNotes from '../components/hnwActivityNotes/HnWActivityNotes';
import { createBrowserHistory } from 'history';
import { useLocation } from 'react-router-dom';

export const history = createBrowserHistory();

export default function ActivityGoal({ routeTo, routeReplaceTo }) {
  const dispatch = useDispatch();

  const bmi = useSelector((state) => state.bmi);
  const customerGender = Object.keys(GENDER).find(
    (item) => GENDER[item] === getCustomerGender(),
  );
  const customerAge = getCustomerAge();
  const defaultValues = getDefaultValues(bmi, customerAge, customerGender);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const activeFlow = params.get('activeFlow');
  const activityGoalId = params.get('activityGoal_id');

  const [isLoading, setLoading] = useState(false);
  const [openMenuPopup, setOpenMenuPopup] = useState(false);
  const [activeCategory, setActiveCategory] = useState('');
  const [selectedExerciseMenu, setSelectedExerciseMenu] = useState([]);
  const [activityEdit, setActivityEdit] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { activityGoal, exerciseMenuList } = useSelector(
    (state) => state.hnwGoals,
  );
  const healthGoals = useSelector((state) => state.goalSetting.healthGoalList);

  const activityId = activityGoal?.id || activityGoalId || '';
  const [activityState, activityDispatch] = useReducer(
    activityReducer,
    HNW_GOAL_SETTING_ACTIVITY_INITIAL_STATE,
  );

  useEffect(() => {
    setLoading(true);
    dispatch(fetchBmiData()).then(() => {
      const customer = getDataFromSession(
        HNW_GOAL_SETTING.CUSTOMER_SESSION_KEY,
      );
      const didMount = async (cId) => {
        await getHealthGoals(cId);
      };
      if (customer?._id) {
        didMount(customer._id, activityId);
      }
    });
  }, []);

  async function getHealthGoals(cId) {
    setLoading(true);
    try {
      if (!activityGoal) {
        await dispatch(fetchActivityGoalAction(cId));
      }
      if (!healthGoals?.waterGoal || !healthGoals?.foodGoal || !healthGoals?.activityGoal) {
        await dispatch(getHnwAllHealhGoalList(cId));
      }
      setLoading(false);
    } catch (error) {
      console.error('error', error);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (activityGoal) {
      if (!activityGoal?.id) {
        activityGoal['targetCalories'] =
          round(defaultValues.defaultExercise, 0) || 0;
      }
      activityDispatch({
        type: ON_LOAD_ACTIVITY,
        payload: activityGoal,
      });
    }
  }, [activityGoal, defaultValues.defaultExercise]);

  const onValueChange = (name, value) => {
    activityDispatch({
      type: ON_CHANGE_ACTIVITY,
      payload: { [name]: +value },
    });
  };

  const closePopupAndClearState = () => {
    setOpenMenuPopup(false);
    dispatch(resetExerciseMenu);
    setSelectedExerciseMenu([]);
  };

  const onCancelExerciseMenu = () => {
    closePopupAndClearState();
  };

  const onSaveExerciseMenu = () => {
    closePopupAndClearState();
    activityDispatch({
      type: ON_CHANGE_ACTIVITIES,
      payload: {
        exerciseCategory: activeCategory,
        exerciseMenu: selectedExerciseMenu,
      },
    });
  };

  const onClickExerciseMenu = (exerciseMenu) => {
    if (selectedExerciseMenu.map((a) => a.id).includes(exerciseMenu.id)) {
      setSelectedExerciseMenu(
        selectedExerciseMenu.filter((a) => a.id !== exerciseMenu.id),
      );
    } else {
      setSelectedExerciseMenu([...selectedExerciseMenu, exerciseMenu]);
    }
  };

  const handleCardValueChange = (
    exerciseCategoryId,
    value,
    name,
    exerciseMenuId,
  ) => {
    activityDispatch({
      type: ON_CHANGE_EXERCISE_MENU,
      payload: {
        exerciseCategoryId,
        exerciseMenuId,
        value,
      },
    });
  };

  const handleCheckValueChange = (
    exerciseCategoryId,
    value,
    name,
    exerciseMenuId,
  ) => {
    activityDispatch({
      type: ON_CHECK_EXERCISE_MENU,
      payload: {
        exerciseCategoryId,
        exerciseMenuId,
        value,
      },
    });
  };

  const clearAllChecksByCategoryOnCancel = (exerciseCategoryId) => {
    activityDispatch({
      type: ON_CANCEL_CHECKS,
      payload: {
        exerciseCategoryId,
      },
    });
  };

  const calculatedCheckboxState = useMemo(() => {
    const cardList = activityState?.exerciseCategory.filter((a) =>
      activityEdit.includes(a.id),
    );
    const allChecked = cardList
      .flatMap((a) => a.exerciseMenu)
      .every((obj) => obj.isChecked);
    const allUnchecked = cardList
      .flatMap((a) => a.exerciseMenu)
      .every((obj) => !('isChecked' in obj) || !obj.isChecked);

    if (allChecked) {
      return 'checked';
    } else if (allUnchecked) {
      return 'unchecked';
    } else {
      return 'intermediate';
    }
  }, [activityState, activityEdit]);

  const handleListCheckValueChange = () => {
    activityDispatch({
      type: ON_ALL_CHECK_BY_STATE,
      payload: { calculatedCheckboxState },
    });
  };

  const findUnboundedActivityValue = useMemo(() => {
    const isDisabled = [];
    if (
      parseInt(activityState.targetCalories) <
      HNW_GOAL_SETTING.ACTIVITY.BURN_CALORIE_MIN_VALUE ||
      parseInt(activityState.targetCalories) >
      HNW_GOAL_SETTING.ACTIVITY.BURN_CALORIE_MAX_VALUE
    ) {
      isDisabled.push(true);
    }
    if (
      parseInt(activityState.exerciseTime) <
      HNW_GOAL_SETTING.ACTIVITY.EXERCISE_TIME_MIN_VALUE ||
      parseInt(activityState.exerciseTime) >
      HNW_GOAL_SETTING.ACTIVITY.EXERCISE_TIME_MAX_VALUE
    ) {
      isDisabled.push(true);
    }

    const suggestionTimeList = activityState?.exerciseCategory
      ?.flatMap((a) => a.exerciseMenu)
      ?.filter((a) => a)
      ?.map((a) => a.suggestionTime);
    for (let i = 0; i < suggestionTimeList?.length; i++) {
      if (
        parseInt(suggestionTimeList[i]) <
        HNW_GOAL_SETTING.ACTIVITY.EXERCISE_TIME_MIN_VALUE ||
        parseInt(suggestionTimeList[i]) >
        HNW_GOAL_SETTING.ACTIVITY.EXERCISE_TIME_MAX_VALUE
      ) {
        isDisabled.push(true);
      }
    }
    return isDisabled.includes(true);
  }, [activityState]);

  const onAddNewMenu = async (exerciseId) => {
    setOpenMenuPopup(true);
    setActiveCategory(exerciseId);
    try {
      if (!exerciseMenuList?.length) {
        setLoading(true);
        await dispatch(fetchMenuByExerciseId(exerciseId));
        setLoading(false);
      }
    } catch (error) {
      console.error('error', error);
      setLoading(false);
    }
  };
  const onSubmitActivity = async () => {
    setLoading(true);
    try {
      const customer = getDataFromSession(
        HNW_GOAL_SETTING.CUSTOMER_SESSION_KEY,
      );
      if (activityId) {
        await dispatch(
          updateActivityGoal({
            activityId,
            activityState: new ActivityGoalCreatePayload(activityState),
          }),
        );
        await dispatch(fetchActivityGoalAction(customer?._id));
      } else {
        await dispatch(
          createActivityGoal(new ActivityGoalCreatePayload(activityState)),
        );
      }
      if (parseInt(activeFlow) === 1) {
        const goalId = healthGoals?.[HEALTH_GOAL_TYPES.NUTRITION]?.id || null;
        routeReplaceTo(
          'pathHnwGoalsNutrition',
          goalId
            ? `?${HEALTH_GOAL_TYPES.NUTRITION}_id=${goalId}&activeFlow=${activeFlow}`
            : `?activeFlow=${activeFlow}`,
        );
      } else {
        routeTo('pathHnwGoalsHome');
      }
      setLoading(false);
    } catch (error) {
      console.error('error', error);
      setLoading(false);
    }
  };
  const onSubmitDeletedMenu = async () => {
    setLoading(true);
    setIsDeleteModalOpen(false);
    try {
      const customer = getDataFromSession(
        HNW_GOAL_SETTING.CUSTOMER_SESSION_KEY,
      );
      const activityStateFiltered = {
        ...activityState,
        exerciseCategory: activityState.exerciseCategory.map((ec) => {
          return {
            ...ec,
            exerciseMenu: ec?.exerciseMenu
              ? ec?.exerciseMenu.filter((em) => !em?.isChecked)
              : [],
          };
        }),
      };
      if (activityId) {
        await dispatch(
          updateActivityGoal({
            activityId,
            activityState: new ActivityGoalCreatePayload(activityStateFiltered),
          }),
        );
        await dispatch(fetchActivityGoalAction(customer?._id));

        setActivityEdit([]);
      } else {
        await dispatch(
          createActivityGoal(
            new ActivityGoalCreatePayload(activityStateFiltered),
          ),
        );
        setActivityEdit([]);
      }
      setLoading(false);
      if (parseInt(activeFlow) === 1) {
        const goalId = healthGoals?.[HEALTH_GOAL_TYPES.NUTRITION]?.id || null;
        routeReplaceTo(
          'pathHnwGoalsNutrition',
          goalId
            ? `?${HEALTH_GOAL_TYPES.NUTRITION}_id=${goalId}&activeFlow=${activeFlow}`
            : `?activeFlow=${activeFlow}`,
        );
      } else {
        routeTo('pathHnwGoalsHome');
      }
    } catch (error) {
      console.error('error', error);
      setLoading(false);
    }
  };

  const dynamicCalculateUnit = (item, type) => {
    switch (type) {
      case 'menu':
        return `${HNW_GOAL_SETTING.ACTIVITY.EXERCISE_TIME_UNIT} (${parseInt(item?.calorieBurnTime) * parseInt(item?.suggestionTime)
          } ${HNW_GOAL_SETTING.ACTIVITY.BURN_CALORIE_UNIT})`;
      default:
        return HNW_GOAL_SETTING.ACTIVITY.EXERCISE_TIME_UNIT;
    }
  };

  return (
    <div className="hnw-goals-activity-container">
      <CalendarLoader showLoading={isLoading} />
      <div
        className={`activity-goal-details-page ${openMenuPopup ? '--no_scroll' : ''
          }`}
      >
        <div className="header-container">
          <div className="container-fluid header-wrapper">
            <div className="row align-items-center">
              <div className="col-12">
                <h2 className="home-title">
                  <img src="/images/healthAndWellness/goals/common/burn.svg" />
                  <span>{HNW_GOAL_SETTING.ACTIVITY.HEADER}</span>
                </h2>
              </div>
            </div>
          </div>
          <img
            src="/images/healthAndWellness/goal-setting/goal-header-curve.svg"
            className="w-100 activity-header-curve-img"
          />
        </div>
        <div className="activity-goal-details-container">
          <div className="container-fluid">
            <div className="activity-goal-details-inner-wrapper">
              <div className="activity-main-goal-container">
                <HnwSetterCard
                  headerText={HNW_GOAL_SETTING.ACTIVITY.BURN_CALORIE_HEADER}
                  unit={
                    round(defaultValues.defaultExercise, 0) ===
                      parseInt(activityState?.targetCalories)
                      ? HNW_GOAL_SETTING.ACTIVITY.BURN_CALORIE_UNIT_RECOMMENDED
                      : HNW_GOAL_SETTING.ACTIVITY.BURN_CALORIE_UNIT
                  }
                  defaultValue={round(defaultValues.defaultExercise, 0)}
                  resetEnabled={
                    round(defaultValues.defaultExercise, 0) !==
                    parseInt(activityState?.targetCalories)
                  }
                  value={`${round(activityState.targetCalories, 0)}`}
                  onValueChange={value =>
                    onValueChange('targetCalories', round(value, 0))
                  }
                  maxValue={HNW_GOAL_SETTING.ACTIVITY.BURN_CALORIE_MAX_VALUE}
                  minValue={HNW_GOAL_SETTING.ACTIVITY.BURN_CALORIE_MIN_VALUE}
                  stepperVal={
                    HNW_GOAL_SETTING.ACTIVITY.BURN_CALORIE_STEPPER_VALUE
                  }
                  footerEnabled={false}
                  footerTextArr={[]}
                  borderColor="black"
                />
                <HnwSetterCard
                  headerText={HNW_GOAL_SETTING.ACTIVITY.EXERCISE_TIME_HEADER}
                  unit={
                    HNW_GOAL_SETTING.ACTIVITY.EXERCISE_TIME_DEFAULT_VALUE ===
                      parseInt(activityState?.exerciseTime)
                      ? HNW_GOAL_SETTING.ACTIVITY.EXERCISE_TIME_UNIT_RECOMMENDED
                      : HNW_GOAL_SETTING.ACTIVITY.EXERCISE_TIME_UNIT
                  }
                  defaultValue={`${HNW_GOAL_SETTING.ACTIVITY.EXERCISE_TIME_DEFAULT_VALUE}`}
                  resetEnabled={
                    HNW_GOAL_SETTING.ACTIVITY.EXERCISE_TIME_DEFAULT_VALUE !==
                    parseInt(activityState?.exerciseTime)
                  }
                  value={`${round(activityState.exerciseTime)}`}
                  onValueChange={(value) =>
                    onValueChange('exerciseTime', round(value))
                  }
                  maxValue={HNW_GOAL_SETTING.ACTIVITY.EXERCISE_TIME_MAX_VALUE}
                  minValue={HNW_GOAL_SETTING.ACTIVITY.EXERCISE_TIME_MIN_VALUE}
                  stepperVal={
                    HNW_GOAL_SETTING.ACTIVITY.EXERCISE_TIME_STEPPER_VALUE
                  }
                  footerEnabled={false}
                  footerTextArr={[]}
                  enableQuickAccessAction={true}
                  quickAccessActionMultiplier={
                    HNW_GOAL_SETTING.ACTIVITY.EXERCISE_QUICK_ACCESS_MULTIPLIER
                  }
                  quickAccessActionArr={
                    HNW_GOAL_SETTING.ACTIVITY.EXERCISE_TIME_HOUR_OPTIONS
                  }
                  logStepperValueSuffix={
                    HNW_GOAL_SETTING.ACTIVITY.EXERCISE_TIME_HOUR_UNIT_VALUE
                  }
                  borderColor="black"
                />
                <ul className="activity-goal-details-list">
                  <HnWActivityNotes />
                </ul>
              </div>

              <div className="activity-sub-goal-container">
                <div className="activity-exercise-details-card-header">
                  <img src="/images/healthAndWellness/goals/activity/exercise.svg" />
                  <span>{HNW_GOAL_SETTING.ACTIVITY.EXERCISE_HEADER}</span>
                </div>
                <ul className="mb-3">
                  <li>{HNW_GOAL_SETTING.ACTIVITY.EXERCISE_NOTE}</li>
                </ul>
                <div className="exercise-goal-card-wrapper">
                  {activityState &&
                    activityState?.exerciseCategory?.map((ec) =>
                      ec.exerciseMenu && ec.exerciseMenu.length > 0 ? (
                        <HnwSetterCardList
                          key={`HnwSetterCardList_${ec.id}`}
                          collectionId={HNW_GOAL_SETTING.ACTIVITY.COLLECTION_ID}
                          collectionValue={
                            HNW_GOAL_SETTING.ACTIVITY.COLLECTION_VALUE
                          }
                          collectionImage={
                            HNW_GOAL_SETTING.ACTIVITY.COLLECTION_IMAGE
                          }
                          headerText={ec.name}
                          headerImage={ec?.image}
                          cardDetailsList={ec.exerciseMenu}
                          btnText={HNW_GOAL_SETTING.ACTIVITY.ADD_NEW_BTN_TEXT}
                          hnwSetterCardOptions={{
                            unit: '',
                            dynamicCalculationType: 'menu',
                            dynamicCalculateUnit,
                            stepperVal:
                              HNW_GOAL_SETTING.ACTIVITY
                                .EXERCISE_TIME_STEPPER_VALUE,
                            maxValue:
                              HNW_GOAL_SETTING.ACTIVITY.EXERCISE_TIME_MAX_VALUE,
                            minValue:
                              HNW_GOAL_SETTING.ACTIVITY.EXERCISE_TIME_MIN_VALUE,
                            defaultValue: `${HNW_GOAL_SETTING.ACTIVITY.EXERCISE_TIME_DEFAULT_VALUE}`,
                            borderColor: 'black',
                          }}
                          showAddBtn
                          showEditBtn
                          isEditMode={activityEdit.includes(ec.id)}
                          onAddBtnClick={() => onAddNewMenu(ec.id)}
                          onEditBtnClick={() => {
                            setActivityEdit([...activityEdit, ec.id]);
                          }}
                          onCancelBtnClick={() => {
                            setActivityEdit(
                              activityEdit.filter((a) => a !== ec.id),
                            );
                            clearAllChecksByCategoryOnCancel(ec.id);
                          }}
                          onValueChange={(value, name, id) =>
                            handleCardValueChange(ec.id, value, name, id)
                          }
                          onCheckValueChange={(value, name, id) =>
                            handleCheckValueChange(ec.id, value, name, id)
                          }
                        />
                      ) : (
                        <HnwAddItemCard
                          key={`HnwAddItemCard_${ec.id}`}
                          imgUrl={ec?.image}
                          title={ec.name}
                          handleAddButtonClick={() => onAddNewMenu(ec.id)}
                        />
                      ),
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-container-wrapper">
          <img
            src="/images/healthAndWellness/goals/activity/footer-wave.svg"
            className="w-100 footer-curve-bg"
          />
          {activityEdit.length ? (
            <div className="footer-container-btn-wrapper">
              <HnwCheckbox
                state={calculatedCheckboxState}
                label={HNW_GOAL_SETTING.ACTIVITY.CHECKBOX_LABLE}
                onCheckValueChange={handleListCheckValueChange}
              />
              <button
                className={`btn ${calculatedCheckboxState === 'unchecked'
                  ? 'btn-disabled'
                  : 'btn-red'
                  } w-100`}
                disabled={calculatedCheckboxState === 'unchecked'}
                onClick={() => setIsDeleteModalOpen(true)}
              >
                {HNW_GOAL_SETTING.ACTIVITY.DELETE_BTN_LABLE}
              </button>
            </div>
          ) : (
            <div className="footer-container-btn-wrapper">
              <button
                className={`btn ${findUnboundedActivityValue ? 'btn-disabled' : 'btn-green'
                  } w-100`}
                disabled={findUnboundedActivityValue}
                onClick={onSubmitActivity}
              >
                {HNW_GOAL_SETTING.ACTIVITY.SUBMIT_BTN_LABLE}
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="activity-goal-setting-custom-modal">
        <HnWNumpadModal openNumpad={openMenuPopup}>
          <HnwExerciseMenuPage
            openMenuPopup={openMenuPopup}
            activityState={activityState}
            selectedExerciseMenu={selectedExerciseMenu}
            exerciseMenuList={exerciseMenuList}
            onClickCancel={onCancelExerciseMenu}
            onClickSave={onSaveExerciseMenu}
            onClickExerciseMenu={onClickExerciseMenu}
          />
        </HnWNumpadModal>
      </div>
      {isDeleteModalOpen && (
        <HealthDeleteFoodModal
          isDeleteModalOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={onSubmitDeletedMenu}
          cancelBtnMsg={FOOD_DELETE_MODAL_CANCEL}
          deleteBtnMsg={FOOD_DELETE_MODAL_DELETE}
          deleteHeaderMsg={FOOD_DELETE_MODAL_HEADER}
        />
      )}
    </div>
  );
}
